import { FavouritesCol, FavouriteLot } from '~/models'

// detecting localStorage
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

const storageAvailable = () => {
  try {
    const storage = window.localStorage
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

const isItemInFavs = (lot_id: string, item_type: string): boolean => {
  const favCol = getFavourites()

  if (favCol !== null) {
    if (item_type === 'lot') {
      return favCol.lots.some((elem: FavouriteLot) => elem.id === lot_id)
    }
  }
  return false
}

const getFavourites = (): FavouritesCol => {
  if (!storageAvailable()) {
    return getNewFavCol()
  }
  let favCol = JSON.parse(localStorage.getItem('favorites')) as FavouritesCol
  if (!favCol) {
    favCol = getNewFavCol()
  }
  return favCol
}

const setFavourites = (fav_col: FavouritesCol) => {
  localStorage.setItem('favorites', JSON.stringify(fav_col))
}

const setLocalStorageFavouriteItem = (item_obj: Object, item_type: string) => {
  let item_id: string = null

  if (item_type === 'lot') {
    item_id = (item_obj as FavouriteLot).id
  }

  if (item_id !== null) {
    if (!isItemInFavs(item_id, item_type)) {
      addToLocalStorageFavouriteItem(item_obj, item_type)
    } else {
      removeFromLocalStorageFavouriteItem(item_id, item_type)
    }
  } else {
    console.log(
      'Favourite item id was not present in the Fav item to be stored'
    )
  }
}

const addToLocalStorageFavouriteItem = (
  item_obj: object,
  item_type: string
) => {
  const favCol = getFavourites()

  if (item_type === 'lot') {
    favCol.lots.push(item_obj as FavouriteLot)
  }

  setFavourites(favCol)
}

const removeFromLocalStorageFavouriteItem = (
  item_id: string,
  item_type: string
) => {
  const favCol = getFavourites()

  if (item_type === 'lot') {
    const index = favCol.lots.findIndex((elem) => elem.id === item_id)

    if (index >= 0) {
      favCol.lots.splice(index, 1)
    }
  }

  setFavourites(favCol)
}

const setSharedToLocalStorage = (value: string) => {
  localStorage.setItem('shared-with-me', value)
}

const getLocalStorageItem = (name: string) => {
  return localStorage.getItem(name)
}

const getNewFavCol = (): FavouritesCol => {
  const newFavCol: FavouritesCol = {
    lots: [],
  }

  return newFavCol
}

export {
  setLocalStorageFavouriteItem,
  setSharedToLocalStorage,
  getFavourites,
  isItemInFavs,
  getLocalStorageItem,
  removeFromLocalStorageFavouriteItem,
  storageAvailable,
}
