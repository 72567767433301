






















































import { Component, Emit, Prop, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import BaseInputComponent from './BaseInput.vue'
import { getMarketingLeadUsers } from '~/api/marketing.api'
import { MarketingLeadUser, User } from '~/models'
import debounce from '~/utils/debounce'

@Component({
  components: {},
  computed: {
    ...mapGetters('user', {
      isBuilder: 'isBuilder',
      user: 'user',
    }),
  },
})
export default class MarketingTextInput extends BaseInputComponent {
  isBuilder: boolean
  user: User

  @Prop({ default: '' })
  value: string

  @Prop({ default: '' })
  type: string

  @Prop({ default: '' })
  lookup: string

  @Prop({ default: true })
  editable: boolean

  options: Array<MarketingLeadUser> = []
  open: boolean = false

  selected: any = null
  currentValue: string = ''

  isLoading: boolean = false

  prefix: string = 'Search'
  searchInMarketing: Function = (): void => {}

  mounted() {
    this.currentValue = this.value
    this.searchInMarketing = debounce(this.search, 500)
  }

  async search(name: string): Promise<void> {
    const filters: { builder?: number; search: string } = { search: name }
    this.isLoading = true

    if (this.isBuilder && this.user.builder) {
      filters.builder = this.user.builder.id
    }

    try {
      const response = await getMarketingLeadUsers(filters)
      this.options = response
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }

    this.setupOpen(true)
    this.cleanRealtorSelected()
  }

  @Emit('input')
  handleInput(event: any): string {
    this.currentValue = event.target.value
    this.searchInMarketing(this.currentValue)
    return this.currentValue
  }

  @Emit('marketingUserSelected')
  select(option: any): any {
    this.setupOpen(false)
    this.options = []
    return option
  }

  setupOpen(isOpen: boolean): void {
    this.open = isOpen
  }

  cleanRealtorSelected(): void {
    this.selected = null
  }

  handleInputBlur(): void {
    this.isLoading = false
    this.setupOpen(true)
  }

  @Watch('options', { deep: true })
  onOptionsChange() {
    this.isLoading = false
  }

  @Watch('value', { deep: true })
  onValueChange() {
    this.currentValue = this.value
  }
}
