/**
 * The values for this enum are the endpoints will be call
 */

export enum FilterTypes {
  BUILDERS = 'builders',
  MATERIAL_CATEGORIES = 'material_categories',
  LOT_SIZES = 'lot_sizes',
  STYLES = 'styles',
  LOT_NEIGHBORHOODS = 'lot_neighborhoods',
  CONSTRUCTION_PHASES = 'construction_phases',
  LOT_TYPES = 'lot_types',
  MASTER_PLANS = 'master_plans',
  PLANS = 'plans',
  ARCHITECTURAL_STATUS = 'architectural_status',
  PLAN_PRICES = 'price',
  PLAN_SQFT = 'sqft',
  BEDS = 'beds',
  BATHS = 'baths',
  STORIES = 'stories',
  GARAGES = 'garage',
  STATUS = 'status',
  TAKEDOWN_DATE = 'takedown_date',
  SEGMENTATION = 'segmentation',
  BLOCKS = 'blocks',
  PLATS = 'plats',
  AVAILABLE = 'available',
  LOT_AVAILABLE = 'lot_available',
  ORDER_BY = 'order_by',
  ARCH_PENDING_VISUAL_TYPES = 'previous_status',
  ARCH_APPROVAL_TYPES = 'approval_type',
  VARIANCE = 'variance',
  HIGH_PROFILE = 'high_profile',
  MISC_SUBMITTAL_TYPE = 'type',
  PHASE = 'phase',
  CLOSING_DATE = 'closing_date',
  ORDER_BY_PARCEL_IQ = 'order_lots_by',
  BUILDER_CONTRACTS = 'builder_contracts',
  IS_ACTIVE = 'is_active',
  EMAIL_NOTIFICATIONS = 'email_notifications',
  PENDING_FIELDS_UPDATED = 'pending_fields_updated',
  SUB_PHASES = 'sub_phase',
}
